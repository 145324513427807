<script>
import DatePicker from './date_picker.vue'

export default {
  extends: DatePicker,
  computed: {
    isTimePicker() {
      return true
    }
  }
}
</script>
